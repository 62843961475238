import React from 'react';

import Link           from '@interness/web-core/src/components/elements/Link/Link';
import Wrapper        from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import SocialMediaBar from '@interness/web-core/src/components/modules/SocialMediaBar/SocialMediaBar';

import * as S         from './styles';
import { useContact } from '@interness/web-core/src/hooks/useContact';

const Footer = () => {
  const date = new Date();
  const contact = useContact();
  return (
    <S.Footer>
      <S.Container>
        <p>© Copyright {date.getFullYear()} {contact.company_name}</p>
        <ul>
          <li><Link to="/kontakt">Kontakt</Link></li>
          <li><Link to="/impressum">Impressum</Link></li>
          <li><Link to="/datenschutz">Datenschutz</Link></li>
          <li><Link to="/agb">AGB</Link></li>
          <li><Link to="/widerruf">Widerruf</Link></li>
          <li><Link to="/versand-und-zahlung">Versand und Zahlung</Link></li>
        </ul>
        <SocialMediaBar/>
      </S.Container>
    </S.Footer>
  )
}

export default Footer;
