import styled from '@emotion/styled';

export const Footer = styled.footer`
  text-align: center;
  padding: 20px 20px;
  margin-bottom: 40px;
  background-color: var(--chakra-colors-gray-50);
  color: ${props => props.theme.brand_color};
`;

export const Container = styled.div`
  display: flex;
  font-size: 0.8rem;
  font-weight: 400;
  align-items: center;
  justify-content: space-between;
  > ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0;
    li {
      a {
        color: unset;
        margin: 0 10px;
        text-decoration: none;
        &:hover {
          color: ${props => props.theme.brand_color};
        }
      }
    }
  }
  // Social Media Bar
  > nav ul {
    justify-content: flex-end;
  }
  > * {
    width: 30%;
  }
  p, ul, li {
    margin-bottom: 0;
  }
`;